import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import {
  Container,
  Row,
  CardGroup,
  Col,
  Media,
  CardDeck,
  Button
} from "reactstrap";
import { FormattedMessage } from 'react-intl';
import Layout from '../components/Layout';
import HeadMeta from '../components/HeadMeta';
import Title from '../components/UIComponents/Title';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import TriDymeIcon from '../media/TriDymeIconV1.svg';
import CardApp from '../components/UIComponents/Cards/CardApp';

import tridymeFeatures from '../data/features';

const {
  REACT_COMPANY_NAME,
  REACT_APP_URL
} = process.env;

const metaData = {
  en: {
    title: `TriDyme | Plugins`,
    pageTitle: 'Plugins',
    description: "TriDyme offers plugins that can be connected to your favorite softwares"
  },
  fr: {
    title: `TriDyme | Plugins`,
    pageTitle: 'Plugins',
    description: "TriDyme propose des plugins qui peuvent se connecter à vos logiciels clients lourds"
  }
};


const PluginsPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeadMeta
      locale={locale}
      metaData={metaData}
    />
    <Container className="pluginsContainer">
      <Row>
        <Title
          icon={TriDymeIcon}
          title={metaData[locale].pageTitle}
          description={metaData[locale].description}
        // itemNumber={data.allMarkdownRemark.totalCount}
        />
      </Row>
      <hr />
      <Row>
        <Col lg={12}>
          {
            tridymeFeatures.plugins.categoriesList.map((category, i) => (
              <div key={i}>
                <Row>
                  <Col lg={12}>
                    <h4 className="appgrouptitle">{category.label}</h4>
                  </Col>
                </Row>
                <CardDeck>
                  {
                    tridymeFeatures.plugins.applicationsList.map((app, index) => (
                      <div key={index}>
                        { (category.value === app.category)
                          &&
                          <Zoom
                          >
                            <CardApp
                              key={index}
                              colnum={4}
                              imgsrc={app.imgsrc}
                              title={app.title}
                              subtitle={app.category}
                              description={app.description}
                              link={app.link}
                              tags={app.tags}
                              href={app.href}
                              status={app.status}
                              logoCompany={app.logoCompany}
                              company={app.company}
                              video={app.video}
                            // openModal={showVideo}
                            />
                          </Zoom>
                        }
                      </div>
                    ))
                  }
                </CardDeck>
                <br />
              </div>
            ))
          }
        </Col>
      </Row>
      <Row>
        <Col md={4} />
        <Col md={4} className="align-center">
          <Button
            className="mainButton"
            href={`${REACT_APP_URL}/dashboard`}
            color="primary"
            size="lg">
            Voir Plus
          </Button>
        </Col>
        <Col md={4} />
      </Row>
      <Row className="bottomPage">
        <br className="bottomPage" />
      </Row>
    </Container>
  </Layout>
)

PluginsPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default PluginsPage;
